.outbox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // min-width: max-content
  z-index: 3;
  position: relative;
  input {
    // border: 1px solid grey
    border: none;
    outline: none;
    background: transparent;
    border-bottom: 1px solid black;
    box-shadow: none;
    padding: 0;
    height: 45px;
    cursor: default; }
  input::placeholder {
    padding-left: .5rem;
    color: white !important; } }
.sugges_outbox {
  min-width: min-content;
  background: white;
  position: absolute;
  top: 2rem;
  width: 100%;
  text-transform: capitalize; }
.sugges_box {}

.sugges_category {
  padding-left: 1rem;
  font-size: 1.1rem;
  color: white;
  background: #17a2b8; }
.sugges_arr {
  display: flex;
  flex-direction: column; }
.sugges_value {
  font-size: 1rem;
  color: grey;
  padding: 0 2rem;
  white-space: nowrap;
  width: min-content;
  min-width: 100%;
  &:hover {
    background: #f9b900;
    width: 100%;
    cursor: pointer; } }



