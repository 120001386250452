//breakpoints
$mobileS: 320px;
$mobileM: 375px;//iphone
$mobileL: 425px;//plus 414px
$tablet: 768px;
$laptop: 1024px;
$laptopL: 1440px;
$desktop: 2560px;

@mixin device($point) {
  @if $point == mobileS {
    @media (min-width: $mobileS) {
      @content; } }
  @else if $point == mobileM {
    @media (min-width: $mobileM) {
      @content; } }
  @else if $point == mobileL {
    @media (min-width: $mobileL) {
      @content; } }
  @else if $point == tablet {
    @media (min-width: $tablet) {
      @content; } }
  @else if $point == laptop {
    @media (min-width: $laptop) {
      @content; } }
  @else if $point == laptopL {
    @media (min-width: $laptopL) {
      @content; } }
  @else if $point == desktop {
    @media (min-width: $desktop) {
      @content; } } }

@mixin transition($x...) {
    -webkit-transition: all $x cubic-bezier(0.645, 0.045, 0.355, 1);
    -moz-transition: all $x cubic-bezier(0.645, 0.045, 0.355, 1);
    -ms-transition: all $x cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all $x cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all $x cubic-bezier(0.645, 0.045, 0.355, 1); }
