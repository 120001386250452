@import url('https://fonts.googleapis.com/css?family=Comfortaa');


.outbox {
  width: 100vw;
  min-height: 100vh;
  background: rgb(244, 244, 244); }

.box {
  width: 100%;
  max-width: 700px;
  padding-top: 200px;
  word-break: break-all;
  margin: 0 auto;
  align-items: center; }


.checkMark {
  display: flex;
  flex-direction: column;
  align-items: center;
  .pic {
    width: 100px;
    height: 100px;
    img {
      width: 100%;
      height: 100%; } }
  .text {
    font-size: 2rem;
    margin-bottom: 20px; } }


.content {
  width: 100%;
  padding: 3%;
  background: white;

  box-shadow: 6px 6px 10px 0px rgba(0,0,0,0.15);
  -webkit-box-shadow: 6px 6px 10px 0px rgba(0,0,0,0.15);
  -moz-box-shadow: 6px 6px 10px 0px rgba(0,0,0,0.15); }

.order {}

.num {
  color: black;
  font-weight: 500;
  margin: 1rem 0; }
.title {
  display: block;
  font-weight: 700;
  margin: 1rem 0; }

.btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  button {
    background: #000;
    color: #fff;
    border: 0;
    border-radius: 0;
    padding: 12px 40px;
    margin: 0 auto;
    &:hover {
      background: #525252;
      color: white; } } }
