@import url('https://fonts.googleapis.com/css?family=Comfortaa');
@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_variables';
@import '~bootstrap/scss/mixins/_breakpoints';


// .outbox > div
//   z-index: 100

.outbox {
  min-width: 320px;
  min-height: 568px;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgb(244, 244, 244); }

.content_box {
  width: 90%;
  height: 80%;
  max-width: 1480px;
  display: flex;
  align-items: center;
  margin-top: 12vh; }

.content {
  width: 100%;
  height: 100%;
  display: flex; }

.image {
  max-width: 50%;
  // max-height: 100%
  overflow: hidden;
  img {
    width: 100%;
    object-fit: cover;

 } }    // height: 100%

.context_outbox {
  width: 50%;
  max-height: 100%;
  margin-left: 3%; }

.context {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: white;
  padding: 40px 25px 25px; }

.title {
  color: black;
  font-size: 1.5rem;
  margin-bottom: 20px; }

.description {
  color: grey;
  font-size: 1rem; }

.price {
  color: black;
  font-size: 1.5rem; }

.body {
  margin-bottom: 20px; }

.border {
  border-bottom: 1px solid #e7e7e7;
  margin: 20px -10px; }

.btns {
  width: 100%;
  margin: 1rem 0;
  display: flex;
  justify-content: space-between;
  button {
    width: 45%;
    margin: 0 auto; } }

@include media-breakpoint-down(lg) {
  .content_box {
    width: 90%; } }

@include media-breakpoint-down(sm) {
  .title {
    font-size:  1rem; }
  .description {
    font-size: 0.7rem; }
  .price {
    font-size: 1rem; }
  .border {
    margin: 0.5rem -0.5rem; }
  .btns {
    .btn {
      font-size: 0.75rem;
      width: 47%;
      padding: 0.25rem 0; } } }
