@import '../../../modules/mediaQuery/app';

.outbox {
  display: flex;
  flex-direction: column;
  min-width: 320px;
  width: auto;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 3;
  background: #fac735;
  flex: 1; }

.tag {
  padding: 10px 15px;
  white-space: nowrap;
  color: white;
  background: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  @include transition(0.3s);
  font-size: 14px;
  &:hover {
    cursor: pointer;
    background: #525252; }
  svg {
    fill: #fff;
    margin-right: 10px; } }

.loggout {
  display: flex;
  align-items: center;
  margin-right: 2rem;
  margin-left: 1rem; }

.login {
  color: #000;
  font-size: 1rem;
  &:hover {
    cursor: pointer;
    color: #383838; } }

.content {
  display: flex;
  height: 54px; }

.left {
  display: flex; }

.logo {
  width: 160px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: bold;
  margin: 0 1rem;
  font-size: 1.5rem;
  &:hover, &:focus {
    cursor: pointer; } }

.mid {
  display: flex;
  margin: auto;
  font-size: 1.1rem;
  color: white !important;
  flex: 1; }

.right {
  display: flex;
  align-items: center;
  margin: 0 71px; }


@media screen and (max-width: $tablet) {
  .content {
    display: flex;
    justify-content: space-between;
    align-items: center; }

  .logo {
    margin-left: auto;
    margin-right: 2rem;
    font-size: 1.3rem;
    flex: 1;
    justify-content: flex-end; }

  .toggle_outbox {
    display: flex;
    width: 3rem;
    flex-wrap: nowrap;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }

  .toggle_icon {
    height: max-content;
    margin: 12px 2rem;
    &:hover, &:blur {
      .bar1 {
        transform: rotate(-45deg) translate(-9px, 6px); }
      .bar2 {
        opacity: 0; }
      .bar3 {
        transform: rotate(45deg) translate(-8px, -8px); } } }

  .bar1, .bar2, .bar3 {
    width: 2rem;
    height: 3px;
    background-color: #fff;
    margin: 5px 0;
    transition: 0.4s; }

  .toggle_content {
    width: 80vw;
    min-width: 120px;
    height: 100vh;
    background: rgb(125,125,125);
    color: white;
    z-index: 1000;
    display: flex;
    flex-direction: column; }

  .side_title {
    width: 100%;
    background: rgb(65,65,65);
    font-size: 1rem;
    padding: .2rem .2rem .2rem .5rem;
    display: flex;
    align-items: center;
    flex-wrap: nowrap; }

  .side_title_close {
    font-size: 1.5rem;
    margin-left: auto;
    margin-right: 1rem;
    &:hover {
      cursor: pointer; } }

  .side_content {
    padding: 0.8rem;
    font-size: .8rem;
    white-space: nowrap;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0,0,0,.5);
    &:hover {
      cursor: pointer;
      color: #0056b3; } }

  #toggle {
    background-color: #FFE600;
    position: fixed;
    top: 0;
    left: 0;
    overflow: scroll; }

  .hide {
    transform: translate3d(-80vw, 0, 0);
    transition: transform .5s linear; }

  .show {
    transform: translate3d(0vw, 0, 0);
    transition: transform .5s linear;
    + .toggle_icon {
      display: none; } } }


