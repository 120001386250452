.loading_background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f9b900;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2; }

.svg {
  width: 30px;
  height: 30px;
  color: red; }
