@import '../../../modules/mediaQuery/app';

.outbox {
  display: flex;
  flex-direction: column;
  font-size: 1em; }

.subtotals {
  display: flex;
  flex-direction: column; }
.row {
  display: flex;
  justify-content: space-between;
  font-size: 1em;
  color: grey;
  margin: 0.1em 0; }
.total {
  font-size: 1.2em;
  color: black;
  font-weight: 500; }

@media screen and (max-width: $tablet) {
  .outbox {
    font-size: .7rem; } }
