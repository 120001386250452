@import '../../../modules/mediaQuery/app';


.outbox {
  width: 250px;
  min-width: 100px;
  background: white;
  margin-top: 1rem;
  padding: 1rem;
  box-shadow: 6px 6px 10px 0px rgba(0,0,0,0.15);
  -webkit-box-shadow: 6px 6px 10px 0px rgba(0,0,0,0.15);
  -moz-box-shadow: 6px 6px 10px 0px rgba(0,0,0,0.15); }

.box {
  display: flex;
  flex-direction: column; }

.title {
  font-size: 1.5rem;
  background: white; }

.title_border {
  border-bottom: 1px solid green;
  padding-bottom: .5rem; }

.tags {
  display: flex;
  flex-wrap: wrap; }

.clear_button {
  background: black;
  color: white;
  padding: 10px;
  border: 0;
  width: 100%; }

.tag {
  padding: .2rem;
  background: rgba(0,0,0,.1);
  margin: .2rem;
  display: flex;
  flex-wrap: nowrap; }

.tag_content {
  margin: 0 .2rem; }

.tag_close {
  padding: 0 .2rem;
  border-left: 1px solid white;
  cursor: pointer; }

.content {
  display: flex;
  flex-direction: column;
  margin-top: 1rem; }

.block {
  margin-bottom: 1rem; }

.sub_title {
 }  // font-size: 1rem







