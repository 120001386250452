@import url('https://fonts.googleapis.com/css?family=Open+Sans');

.outbox {
  display: flex;
  flex-direction: column;
  width: 70%;
  // height: 70px
  margin-bottom: 5px; }

.label {
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
  label {
    margin: 0; } }

.input {
  width: 100%;
  margin-bottom: 3px;
  input {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    border: 1px solid grey;
    padding: 0 0.5rem; } }

.errMsg {
  color: red;
  font-size: 15px; }
