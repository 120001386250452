@import url('https://fonts.googleapis.com/css?family=Roboto');

.outbox {
  width: 100vw;
  height: 100vh;
  background-image: url(../../../assets/images/background.jpg);
  background-size: cover;
  display: flex;
  justify-content: center; }

.box {
  width: 20%;
  min-width: 300px;
  max-height: 50%;
  margin-top: 5%;
  border-radius: 5px; }



