@import '../../../modules/mediaQuery/app';

// .outbox > div > div
//   z-index: 100

.outbox {
  min-width: 320px;
  min-height: 568px;
  max-width: 100vw;
  max-height: 100vh; }

.row {
  margin-left: 0; }

.box {
  display: flex;
  justify-content: space-around;
  margin-top: 12vh;
  max-width: 1300px;
  margin: 120px auto 20px; }

.filter {
  margin: .5rem;
  margin-right: 1rem;
  margin-left:  2rem; }

.products {
  display: flex;
  width: 100%;
  flexWrap: wrap !important;
  margin-top: 0rem;
  margin-left: 0;
  margin-right: 0;
  padding-right: 1rem;

  .items-wrapper {
    background: red; } }

.product {
  min-width: 150px;
  cursor: pointer;
 }  // margin: .5rem
.product > div {
  box-shadow: 6px 6px 10px 0px rgba(0,0,0,0.15);
  -webkit-box-shadow: 6px 6px 10px 0px rgba(0,0,0,0.15);
  -moz-box-shadow: 6px 6px 10px 0px rgba(0,0,0,0.15);
  border: 0;
  border-radius: 0;
  padding: 0 !important;
  height: auto !important;

  & > div {
    padding: 20px 0;
    text-align: center; } }

@media screen and (max-width: $tablet) {
  .box {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-top: 8vh; }

  .filter {
    margin: 0;
    width: 100%; }


  .products {
    width: 100%;
    padding-left: 1rem; } }
