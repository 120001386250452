.outbox {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white; }

.logo {
  width: 200px;
  height: 130px;
  background: rgb(238,130,238);
  font-size: 50px;
  color: white; }

.title_style {
  color: black;
  width: 80%;
  font-size: 20px; }

.border_style {
  width: 80%;
  border-bottom: 1px solid black;
  margin-bottom: 5px; }
