@import url('https://fonts.googleapis.com/css?family=Comfortaa');
@import '../../../modules/mediaQuery/app';

.outbox {
  display: flex;
  flex-direction: column; }

.row {
  display: flex;
  justify-content: space-between;
  margin: 3% 0; }
.pic {
  max-width: 6em;
  margin-right: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: {}
  img {
    max-width: 70%;
    border-radius: 10%; } }

.title {
  margin: 0 .3em;
  font-size: 1em;
  font-family: sans-serif; }

.price {
  margin-left: auto;
  font-size: 1em;
  font-weight: 600; }


@media screen and (max-width: $tablet) {
  .outbox {
    font-size: .7rem; }

  .pic {
    font-size: .6rem; }
  .price {
    font-size: .5rem; } }
