@import url('https://fonts.googleapis.com/css?family=Comfortaa');
@import '../../../modules/mediaQuery/app';


.outbox {
  max-width: 100vw;
  min-width: 320px;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  background: rgb(244, 244, 244);
  font-size: 1rem; }

.box {
  margin: 12vh 0;
  display: flex;
  justify-content: center; }

.content {
  width: 60%;
  background: white;
  display: flex;
  flex-direction: column;
  padding: 2em;
  margin: 2em; }

.title {
  font-size: 24px;
  text-align: center; }
.sub_title {
  color: black;
  font-size: 16px;
  display: block;
  margin: 20px 0 30px; }

.table {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  padding: 2%; }
.prices {
  padding: 2% 0;
  border-bottom: 1px solid black; }
.btn {
  margin: 30px 0 20px;
  text-align: center;
  button {
    background: #000;
    color: #fff;
    border: 0;
    padding: 12px 40px;
    margin: 0 auto;
    border-radius: 0;
    &:hover {
      background: #525252;
      a {
        color: white; } }
    a {
      text-decoration: none;
      color: black; } } }

@media screen and (max-width: $tablet) {
  .outbox {
    font-size: .7rem; }

  .content {
    width: 80%; } }



