@import url('https://fonts.googleapis.com/css?family=Comfortaa');
@import '../../../modules/mediaQuery/app';


.outbox {
  min-width: max-content;
  max-width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  background: #f4f4f4;
  font-size: 1rem; }

.box {
  width: 100%;
  margin-top: 12vh; }



.content {
  width: 100%;
  max-width: 1000px;
  padding: 20px;
  background: white;

  margin: 40px auto;



  box-shadow: 6px 6px 10px 0px rgba(0,0,0,0.15);
  -webkit-box-shadow: 6px 6px 10px 0px rgba(0,0,0,0.15);
  -moz-box-shadow: 6px 6px 10px 0px rgba(0,0,0,0.15); }


.title {
  color: black;
  font-size: 2rem; }

.table {
  width: 100%; }


.process_box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end; }

.total {
  font-size: 20px; }



// @include media-breakpoint-down(md)
//   .box
//     max-width: 70%
// @include media-breakpoint-down(sm)
//   .box
//     max-width: 90%
//   .title
//     font-size: 1.5rem

