@import '../../../modules/mediaQuery/app';

.outbox {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 1rem; }

.auto {
  width: max-content; }

.btn {
  margin-left: 1rem;
  border: 0px solid rgb(94, 114, 100);
  border-radius: 0;
  background: #000000;
  @include transition(0.3s);
  padding: 10px 20px;
  width: 120px;
  text-align: center;
  &:active {
    border-radius: 0; }
  &:hover {
    background: #525252;
    button {
      color: white; } }
  button {
    background: transparent;
    border: 0;
    color: white;
    &:focus {
      outline: none; } } }


@media screen and (max-width: $tablet) {
  .outbox {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: .5rem;
    font-size: .8rem; }

  .auto {
    width: 100%;
    padding: 0 1rem;
    div {
      input {
        width: 100%; } } }

  .btn {
    width: calc( 100% - 2rem);
    margin: .5rem 1rem 0 1rem;
    button {
      width: 100%;
      padding: 12px 40px;
      margin: 0;
      border: none; } } }

