html {
  height: 100%;
  position: absolute;
  width: 100%;
  height: 100%;
}
body {
  background-color: transparent;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a:hover {
  color: #000000
}

.background-wave {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  min-width: 1450px;
  z-index: -1;
}

#root {
  z-index: 100;
}

.payment-modal h3{
  text-align: center;
  margin-bottom: 20px;
}

.payment-modal label{
  width: 100%;
}

.payment-modal {
  width: 100%;
}

.payment-modal .form-check {
  padding-top: 5px;
  padding-bottom: 5px;
}

.payment-modal label .right{
  float: right;
}

.payment-modal label .left{
  float: left;
}

.payment-modal-payer {
  text-align: center;
}

.payment-modal > p {
  text-align: center;
  color: #000;
}

.payment-modal > p {
  text-align: center;
}

.modal-error {
  text-align: center;
}

.modal-error p {
  margin-bottom: 30px;
}

.modal-error h4 {
  color: #f9b900;
}

.payment-modal-buttons {
  text-align: center;
}

.payment-modal-buttons > button {
  margin: 0 5px 10px;
}

.modal-footer > p {
  margin: 0;
}

.btn {
  border-radius: 0;
  border: 0;
  padding: 12px 40px;
}

.btn-primary, .btn-outline-primary {
  background-color: #000;
  color: #fff;
}

.btn-primary:hover, .btn-outline-primary:hover {
  background-color: #525252;
}

.btn-outline-danger {
  background-color: #f9b900;
  color: #fff;
}

.dropdown-menu {
  max-width: 10px !important;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f4f4f4;
}