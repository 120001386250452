@import '../../../modules/mediaQuery/app';


.outbox {
  display: flex;
  margin: auto;
  color: white !important;
  flex: 1; }

.lists {
  display: flex;
  word-break: none;
  white-space: nowrap; }

.tag {
  padding: 0.8rem;
  white-space: nowrap;
  display: flex;
  align-items: center;
  @include transition(0.3s);
  background: #ffb300;
  margin-right: 10px;

  &:hover {
    cursor: pointer;
    background: #ffbf00; } }

@media screen and (max-width: $tablet) {
  .lists {
    display: flex;
    flex-direction: column; }

  .outbox {
    display: flex;
    flex-direction: column;
    margin: 0;
    font-size: .8rem; }

  .tag {
    border-bottom: 1px solid rgba(0,0,0,.5); } }
