@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";

.card {
  min-width: 150px; }

@include media-breakpoint-down(sm) {
  .title {
    font-size: 15px; }
  .subtitle {
    font-size: 12px !important; }
  .color {
    font-size: 12px;
    color: black; } }

// .card

// .image




